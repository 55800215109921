// import "./src/css/variables.scss";

// import "bootstrap/scss/bootstrap.scss";

// import "hamburgers/_sass/hamburgers/hamburgers";

// import "include-media/dist/include-media";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import "./src/css/main.scss";
